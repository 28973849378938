import React from "react"
import Card from "./card"
import * as styles from "./styles.module.css"

const ClientPortals = ({ cards, headline }) => {
    const cardColumnWidth = cards.length > 4 ? 3 : 12 / cards.length
    return (
        <section>
            <div className={`${styles.card__container} container`}>
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="mb-20 mt-20">{headline}</h2>
                    </div>
                </div>
                <div className={`${styles.card__row} row`}>
                    {cards.map((card, index) =>
                        <div key={index} className={`col-md-${cardColumnWidth} ${styles.card_col}`}>
                            <Card 
                                clientPortalLink={card.clientPortalLink} 
                                name={card.associatedTeamMember.name}
                                headshot={card.associatedTeamMember.headshot}
                                jobTitle={card.associatedTeamMember.jobTitle}
                            />
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default ClientPortals