import React from "react"
import * as styles from "./styles.module.css"

const NarrowGenericBanner = ({ banner, title, marginTop, marginBottom, mobileMarginBottom }) => {
    const desktopStyles = {
        marginTop: marginTop,
        marginBottom: marginBottom,
        backgroundImage: `url(${banner.backgroundImage.url})`
    }
    const mobileStyles = {
        marginBottom: mobileMarginBottom,
        backgroundImage: `url(${banner.mobileBackgroundImage.url})`
    }
    return (
        <>
            <div className={`${styles.desktopBanner} ${styles.banner}`}
                style={desktopStyles}
            >
                <h1 className="visuallyhidden">
                    {title}
                </h1>
            </div>

            <div className={`${styles.mobileBanner} ${styles.banner}`}
                style={mobileStyles}
            >
                <h1 className="visuallyhidden">
                    {title}
                </h1>
            </div>
        </>
    )
}

export default NarrowGenericBanner