// extracted by mini-css-extract-plugin
export var blackish = "#121117";
export var circle = "styles-module--circle--aac0b";
export var colors = "\"../../../assets/css/_colors.css\"";
export var cta = "styles-module--cta--fa988";
export var dottedOutline = "styles-module--dottedOutline--1f512";
export var flower = "styles-module--flower--ac024";
export var fonts = "\"../../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var images = "styles-module--images--28fc4";
export var lightBg = "#FFF8E8";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var square = "styles-module--square--a3f19";
export var text = "styles-module--text--798f8";
export var three_shapes = "styles-module--three_shapes--e92d6";
export var two_shapes = "styles-module--two_shapes--56cb7";
export var yellowBtn = "#FFE07B";