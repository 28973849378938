import React from "react"
import { graphql } from "gatsby"
import { Seo } from "../components/seo"
import Layout from "../components/layout"
import ClientPortals from "../components/clientPortals"
import Cta from "../components/cta"
import NarrowGenericBanner from "../components/narrowGenericBanner"

export default function ClientPortalPage({ data: { page }}) {
    return(
        <Layout>
          {page.banner.map((block, index) =>
              <NarrowGenericBanner key={index} banner={block} title={page.title} marginTop={60} marginBottom={60} mobileMarginBottom={60}/>
          )}
          <ClientPortals cards={page.clientPortalLinks} headline={page.headline} />
          {page.cta.map((block, index) =>
            <Cta
                key={index} 
                data={block} 
                type={`${block.model.apiKey === "shapes_cta" ? "shapes" : "default"}`}
            />
          )}
        </Layout>
    )
}

export const data = graphql`
  query ClientPortalQuery {
    page: datoCmsClientPortalPage {
      title
      banner {
        backgroundImage {
          alt 
          data: gatsbyImageData
          url
        }
        mobileBackgroundImage {
          alt 
          data: gatsbyImageData
          url
        }
      }
      headline
      clientPortalLinks {
        clientPortalLink
        associatedTeamMember {
          name
          headshot {
            url
            alt
            data: gatsbyImageData
            focalPoint {
              x
              y
            }
          }
          jobTitle
        }
      }
      cta {
        ... on DatoCmsShapesCta {
            model {
            apiKey
            }
            headline
            body
            shapeType
            buttonText
            buttonUrl
            newTab
        }
      }
      seo {
        title
        description
        image {
          url
        }
      }
    }
  }
`

export const Head = ({ data }) => {
    return (
      <Seo 
        title={data.page.seo.title}
        description={data.page.seo.description}
        image={data.page.seo.image}
      />
    )
  }