import React from "react"
import ShapesCta from "./shapesCta"

const Cta = ({ type, data }) => {
    switch (type) {
        case 'shapes' : 
            return (
                <ShapesCta data={data}/>
            )
        default: 
            return (
                <></>
            )
    }
}
export default Cta