// extracted by mini-css-extract-plugin
export var blackish = "#121117";
export var card = "styles-module--card--53033";
export var card__container = "styles-module--card__container--cefa6";
export var card__row = "styles-module--card__row--e9a9b";
export var card_col = "styles-module--card_col--503a5";
export var colors = "\"../../assets/css/_colors.css\"";
export var container = "styles-module--container--3da5d";
export var cursorContainer = "styles-module--cursorContainer--0fbda";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gatsbyImage = "styles-module--gatsbyImage--3fd60";
export var gtWalsheim = "'gt-walsheim'";
export var header = "styles-module--header--89c8d";
export var hovering = "styles-module--hovering--7633c";
export var imageContainer = "styles-module--imageContainer--f9602";
export var lightBg = "#FFF8E8";
export var lightGreyBg = "#EAEAEA";
export var lightOrange = "#FFDCB7";
export var lightPurple = "#E1DBFF";
export var lightYellowBg = "#FFF0BD";
export var mediumOrange = "#FFB96F";
export var mobile_only = "styles-module--mobile_only--15cb1";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var textContainer = "styles-module--textContainer--d23bb";
export var yellowBtn = "#FFE07B";