import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "../styles.module.css"

const Card = ({ clientPortalLink, name, headshot, jobTitle }) => {
    const [hovering, setHovering] = useState(false)
    const handleMouseEnter = () => {
        setHovering(true)
    }
    const handleMouseLeave = () => {
        setHovering(false)
    }

    return (
        <a href={`${clientPortalLink}`} className={styles.cursorContainer} target="_blank" rel="noreferrer">
            <div 
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={`${hovering ? styles.hovering : "" } ${styles.card} card`}>
                <div className={styles.imageContainer}>
                    <GatsbyImage
                        objectPosition={`${headshot.focalPoint.x*100}% ${headshot.focalPoint.y*100}%`} 
                        className={styles.gatsbyImage} 
                        image={headshot.data} 
                        alt={headshot.alt ? headshot.alt : ""} />
                </div>
                <div className={styles.textContainer}>
                    <h3>{name}</h3>
                    <p>{jobTitle}</p>
                </div>
            </div>
        </a>
    )
}

export default Card