import React from "react"
import Button from "../../button"
import * as styles from "./styles.module.css"

const ShapesCta = ({ data }) => {
    const shapeType = data.shapeType ? "two" : "three"
    const hasBody = data.body ? true : false;
    if(shapeType === "two") {
        return(
            <div className={`${styles.cta} ${styles.two_shapes} container-fluid`}>
                <div className="row">
                    <div className={styles.images}>
                        <div className={`${styles.circle}`}></div>
                        {/* <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="90" cy="90" r="89.5" fill="#FFF8E8" stroke="black" strokeDasharray="2 3"/>
                        </svg>  */}
                        {/* <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="179" height="179" fill="#FFF8E8" stroke="black" strokeDasharray="2 3"/>
                        </svg> */}
                        <div className={styles.square}></div>
                    </div>
                    <div className={`${styles.dottedOutline}`}>
                        <div className={styles.text}>
                            <div>
                                <h2 className="text-left">{data.headline}</h2>
                                {hasBody ? <p className="xl-body">{data.body}</p> : ""}
                            </div>
                            <Button type="jumboPurple" url={data.buttonUrl} text={data.buttonText} newTab={data.newTab}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className={`${styles.cta} ${styles.three_shapes} container-fluid`}>
                <div className="row">
                    <div className={styles.images}>
                        <div className={styles.circle}></div>
                        <div className={styles.flower}></div>
                        <div className={styles.square}></div>
                    </div>
                    <div className={`${styles.dottedOutline}`}>
                        <div className={styles.text}>
                            <h2 className="text-left">{data.headline}</h2>
                            <Button type="jumboPurple" url={data.buttonUrl} text={data.buttonText} newTab={data.newTab}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ShapesCta